<template>
  <div class="body" v-loading="Loading">
    <!-- <div class="page-disable" v-if="MemberType === 'view'"></div> -->
    <div v-if="!isNext">
      <div class="base-msg" v-if="detailForm">
        <p class="title">基本信息</p>
        <div class="base-content">
          <div
            v-if="
              detailForm.base.findIndex((item) => item.field === 'avatar') != -1
            "
          >
            <div class="content-title">
              用户头像
              <p
                class="is-required"
                style="top: 8px"
                v-if="
                  detailForm.base.find((item) => item.field === 'avatar').props
                    .normal.required
                "
              >
                *
              </p>
            </div>
            <SingleMediaWall
              :disabled="MemberType === 'view'"
              v-model="
                detailForm.base.find((item) => item.field === 'avatar')
                  .user_data
              "
              :width="80"
              :height="80"
              borderRadius="50%"
              :useDefaultAvatar="
                detailForm.base.find((item) => item.field === 'avatar')
                  .user_data === ''
              "
              :showMask="false"
            />
          </div>
          <el-table
            border
            :data="[detailForm[BaseData.formKey]]"
            style="width: 100%; margin-top: 30px"
            class="thead-light"
            :header-cell-style="{ padding: '10px 30px' }"
            :cell-style="{ pading: ' 0 30px 10px', background: '#ffffff' }"
            v-for="(BaseData, y) in baseDataConfig"
            :key="y"
          >
            <el-table-column :label="BaseData.label">
              <template slot-scope="scope">
                <div class="table-column">
                  <div
                    class="input-box"
                    v-for="(item, index) in detailForm[BaseData.formKey].filter(
                      (el) => !['avatar'].includes(el.field)
                    )"
                    :key="index"
                  >
                    <div style="padding: 0 0 8px; position: relative">
                      {{
                        (item.props.normal.label || item.title) | deleteEmpty
                      }}
                      <p class="is-required" v-if="item.props.normal.required">
                        *
                      </p>
                      <i @click="refreshList()" class="el-icon-refresh cursor-pointer" v-if="item.field === 'id_card_image'"></i>
                    </div>
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.field === 'base-multi-select'"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                      multiple
                      @change="(val) => selectChange(val, item)"
                    >
                      <el-option
                        v-for="(el, j) in item.props.normal.options"
                        :key="j"
                        :label="el"
                        :value="el"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-else-if="item.formType === 0"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                      @change="(val) => selectChange(val, item)"
                    >
                      <el-option
                        v-for="(el, j) in item.props.normal.options"
                        :key="j"
                        :label="el"
                        :value="el"
                      >
                      </el-option>
                    </el-select>
                    <div v-if="item.field !== 'base-input'">
                      <el-input
                        :disabled="MemberType === 'view'"
                        type="text"
                        v-if="item.formType === 1"
                        v-model="item.user_data"
                        :placeholder="item.props.normal.value"
                      ></el-input>
                      <el-input
                        :disabled="MemberType === 'view'"
                        type="textarea"
                        :rows="1"
                        v-if="item.formType === 2"
                        v-model="item.user_data"
                        :placeholder="item.props.normal.value"
                      ></el-input>
                    </div>
                    <div v-else>
                      <el-input
                        :disabled="MemberType === 'view'"
                        :type="item.props.normal.inputType"
                        autosize
                        v-model="item.user_data"
                        :placeholder="item.props.normal.value"
                      ></el-input>
                    </div>
                    <div v-if="item.formType === 4" style="width: 100%">
                      <el-button
                        :disabled="MemberType === 'view'"
                        class="company-address"
                        @click="
                          handleAddressSelect(
                            item.user_data,
                            item.dependence,
                            item.dependence
                              ? detailForm[BaseData.formKey].find(
                                  (el) => el.user_data === item.dependence
                                ).field
                              : item.field,
                            BaseData.formKey
                          )
                        "
                      >
                        {{
                          item.user_data[item.field] || item.props.normal.value
                        }}</el-button
                      >
                    </div>
                    <div v-if="item.formType === 19" style="width: 100%">
                      <el-button-group
                        style="width: 100%; align-items: stretch; display: flex"
                      >
                        <el-button
                          :disabled="MemberType === 'view'"
                          class="company-address"
                          @click="handleAddressSelect2(item.user_data, index)"
                        >
                          {{
                            item.user_data.address || item.props.normal.value
                          }}</el-button
                        >
                        <el-button
                          :disabled="MemberType === 'view'"
                          v-if="item.user_data.address"
                          icon="el-icon-delete"
                          @click="item.user_data.address = ''"
                        />
                      </el-button-group>
                      <el-input
                        v-model="item.user_data.address"
                        v-show="false"
                      ></el-input>
                    </div>
                    <div v-if="item.formType === 5" style="width: 100%">
                      <SingleMediaWall
                        :disabled="MemberType === 'view'"
                        v-model="item.user_data"
                        :width="150"
                        :height="96"
                        :borderRadius="4"
                        addText="上传"
                      />
                    </div>
                    <template v-if="item.formType === 6">
                      <div
                        v-if="item.field !== 'school_idcard'"
                        class="two-img-upload"
                        style="width: 100%; display: flex"
                      >
                        <SingleMediaWall
                          :disabled="MemberType === 'view'"
                          style="flex: 1"
                          v-model="item.user_data.positive"
                          width="100%"
                          :height="96"
                          :borderRadius="4"
                          :addText="positiveText"
                        />
                        <SingleMediaWall
                          :disabled="MemberType === 'view'"
                          style="flex: 1"
                          v-model="item.user_data.contrary"
                          width="100%"
                          :height="96"
                          :borderRadius="4"
                          :addText="contraryText"
                        />
                      </div>
                      <div
                        v-else
                        class="two-img-upload"
                        style="width: 150px; display: flex"
                      >
                        <SingleMediaWall
                          :disabled="MemberType === 'view'"
                          style="flex: 1"
                          v-model="item.user_data"
                          width="100%"
                          :height="96"
                          :borderRadius="4"
                        />
                      </div>
                    </template>
                    <div class="date-pick" v-if="item.formType === 7">
                      <!-- 选择生日 -->
                      <el-date-picker
                        v-if="item.field === 'birthday'"
                        :disabled="MemberType === 'view'"
                        style="flex: 1"
                        v-model="item.user_data.birthday"
                        :type="datePickerType[item.props.normal.dateType]"
                        :format="formatType[item.props.normal.dateType]"
                        :value-format="formatType[item.props.normal.dateType]"
                        :placeholder="item.props.normal.value"
                      >
                      </el-date-picker>
                      <el-select
                        v-if="item.field === 'birthday'"
                        :disabled="MemberType === 'view'"
                        style="width: 80px"
                        v-model="item.user_data.birthday_type"
                      >
                        <el-option
                          v-for="item in dateTypeOption"
                          :key="item.id"
                          :label="item.label"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <!-- 其他类型选择时间 -->
                      <el-date-picker
                        v-if="item.field !== 'birthday'"
                        :disabled="MemberType === 'view'"
                        style="flex: 1"
                        v-model="item.user_data"
                        :type="getDateType(item)"
                        :format="formatType[item.props.normal.dateType]"
                        value-format="timestamp"
                        :placeholder="item.props.normal.value"
                      >
                      </el-date-picker>
                    </div>
                    <!-- 社会身份，证件类型, 会员类型 -->
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 8"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                      @change="
                        (val) =>
                          handleSelect(val, index, item.field, BaseData.formKey)
                      "
                    >
                      <el-option
                        v-for="el in item.props.normal.options.filter(
                          (op) => op.is_open
                        )"
                        :key="el.field"
                        :label="el.option_name || el.title"
                        :value="el.field"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 9"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                    >
                      <el-option
                        v-for="el in item.props.normal.options"
                        :key="el.id"
                        :label="el.name"
                        :value="el.id"
                      >
                      </el-option>
                    </el-select>
                    <el-date-picker
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 10"
                      v-model="item.user_data"
                      type="date"
                      value-format="timestamp"
                      :placeholder="item.props.normal.value"
                    >
                    </el-date-picker>
                    <el-cascader
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 11"
                      :options="provinceCityData"
                      v-model="item.user_data"
                      :props="optionProp"
                    >
                    </el-cascader>
                    <!-- 身高 -->
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 12"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                    >
                      <el-option
                        v-for="(el, j) in 81"
                        :key="j"
                        :label="139 + el + 'cm'"
                        :value="139 + el"
                      >
                      </el-option>
                    </el-select>
                    <!-- 体重 -->
                    <el-select
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 13"
                      v-model="item.user_data"
                      :placeholder="item.props.normal.value"
                    >
                      <el-option
                        v-for="(el, j) in 81"
                        :key="j"
                        :label="39 + el + 'kg'"
                        :value="39 + el"
                      >
                      </el-option>
                    </el-select>
                    <!-- 省市区选择器 -->
                    <el-cascader
                      :disabled="MemberType === 'view'"
                      style="width: 100%"
                      v-if="item.formType === 14 && region_data && region_data.length"
                      :options="
                        getRegionData(item.props.normal.showType, item.field)
                      "
                      v-model="item.user_data"
                      :props="regionOptionProp"
                      clearable
                    >
                    </el-cascader>
                    <!-- 关联企业表单 -->
                    <el-button
                      class="formType-btn"
                      type="plain"
                      style="width: 100%"
                      :disabled="MemberType === 'view'"
                      v-if="item.formType === 15"
                      @click="
                        handleToRelateCompany(
                          RelateCompanyData || item.user_data
                        )
                      "
                    >
                      {{
                        RelateCompanyData
                          ? RelateCompanyData.status_text
                          : item.user_data.status_text === '请选择'
                          ? '请选择企业'
                          : item.user_data.status_text
                      }}
                    </el-button>
                    <template v-if="item.formType === 20">
                      <div class="flex-1" style="width: 100% ;overflow: auto; max-height: 250px;">
                        <media-wall
                          addText="上传照片"
                          v-model="item.user_data"
                          :width="150"
                          :height="96"
                          :borderRadius="4"
                          :limit="999"
                        >
                        </media-wall></div
                    ></template>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="id-card" style="margin-top: 22px">
            <p style="padding: 8px 0 10px">身份证正反面</p>
            <div class="card-img" style="display: flex">
              <div class="id-card-img">
                <SingleMediaWall v-model="detailForm.detail.id_card_is" :width="150" :height="96"
                  :borderRadius="4" addText="正面"></SingleMediaWall>
              </div>
              <div class="id-card-img">
                <SingleMediaWall v-model="detailForm.detail.id_card_the" :width="150" :height="96"
                  :borderRadius="4" addText="反面"></SingleMediaWall>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div
        :style="MemberType === 'view' ? 'pointer-events: none;' : ''"
        class="favorites-msg"
        v-if="detailForm && MemberType !== 'register'"
      >
        <p class="title">爱好信息</p>
        <div class="favorites-content">
          <p style="padding: 5px 0 15px">喜爱的书籍</p>
          <div style="margin-bottom: 15px">
            <el-tag
              v-for="(book, index) in detailForm.content.favorites.books"
              :key="index"
              closable
              @close="CloseTag(index, 'books')"
            >
              {{ book }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="!showBookBtn"
              size="medium"
              ref="saveBookInput"
              maxlength="15"
              show-word-limit
              v-model="inputValue"
              @keyup.enter.native="handleInputConfirm('books')"
              @blur="handleInputConfirm('books')"
            >
            </el-input>
            <el-button
              class="tag-btn"
              @click="AddTag('books')"
              v-if="showBookBtn"
              >+ 添加</el-button
            >
          </div>
          <p style="padding: 5px 0 15px">喜爱的照片</p>
          <div class="images-box">
            <media-wall
              addText="上传照片"
              v-model="detailForm.content.favorites.images"
              :width="150"
              :height="96"
              :borderRadius="4"
              :limit="9 - detailForm.content.favorites.images.length"
            >
            </media-wall>
          </div>
          <p style="padding: 5px 0 15px">喜爱的视频</p>
          <div class="images-box">
            <media-wall
              v-model="detailForm.content.favorites.movies"
              :width="150"
              :height="96"
              :borderRadius="4"
              addText="上传视频"
              :limit="1 - detailForm.content.favorites.movies.length"
              UploadType="video"
            >
            </media-wall>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isNext">
      <div class="position-list">
        <p class="title" style="margin-bottom: 0px">职务信息</p>
        <PositionList
          type="add"
          :socialText="social_role"
          @change="addPostion"
        />
      </div>

      <div v-if="tenantVersion === 'school'" class="position-list">
        <p class="title" style="margin-bottom: 0px">班级信息</p>
        <ClassList @updateClassData="updateClassData" />
      </div>
    </div>
    <AddressSelector
      v-model="openAddressSelector"
      @change="handleChangeAddress"
      :detailForm="addressForm"
    />
    <AddressSelector
      v-model="openAddressSelector2"
      @change="handleChangeAddress2"
      :detailForm="addressForm"
    />
    <!-- 关联企业弹窗 -->
    <RelateCompanyDialog v-model="openRelateCompanyDialog" :userId="id" />
    <FixedActionBar>
      <el-button v-if="$route.name == 'AddMember' && isNext" @click="GoBack"
        >上一步</el-button
      >
      <el-button
        v-if="MemberType !== 'view'"
        type="primary"
        @click="HandleSave"
        :loading="saveLoading"
      >
        {{
          $route.name !== 'AddMember' || isNext ? '保存' : '下一步'
        }}</el-button
      >
      <el-button @click="GoBack" v-if="!isNext">返回</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  MemberDetail,
  SaveUser,
  userValidate,
} from '../../api/member/member-detail'
import { getRegionSetting } from '@/modules/region-setting/api/list.js'
// const regionData = require('../../assets/jsons/address.json')
// const provinceAndCityData = require('../../../common/json/city.json')
import PositionList from '../../components/Member/PositionList.vue'
import ClassList from '../../components/Member/ClassList.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import AddressSelector from '../../../common/components/AddressSelector.vue'
import RelateCompanyDialog from '../../components/Member/RelateCompanyDialog.vue'
import {getCityJson} from '../../../app-design/api/components'
import {getAddressJson} from '../../../employ/api/common'

export default {
  props: {
    data: {
      type: String,
    },
  },
  components: {
    PositionList,
    AddressSelector,
    SingleMediaWall,
    MediaWall,
    FixedActionBar,
    RelateCompanyDialog,
    ClassList,
  },
  data() {
    return {
      Loading: false,
      id: this.$deCode(this.data).id,
      isNext: false,
      // 会员信息
      detailForm: null,
      baseDataConfig: [],
      Options: {}, // 选择数据列表
      dateTypeOption: [
        { label: '农历', id: 0 },
        { label: '新历', id: 1 },
      ],
      positoins: [],
      addressForm: {
        lat: '',
        lng: '',
        address: '',
      },
      addressFormKey: {
        field: '',
        fromKey: '',
        parentKey: '',
      },
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/images/wxapps/icons/avatar1.png',
      provinceCityData: [], //省市级选项
      region_data: [],
      optionProp: {
        expandTrigger: 'hover',
        label: 'label',
        value: 'label',
      },
      regionOptionProp: {
        expandTrigger: 'hover',
        label: 'label',
        value: 'label',
        checkStrictly: true,
      },
      /* 上传证件正反面文案 */
      positiveText: '',
      contraryText: '',
      inputValue: '',
      currentSelectType: '',
      MemberType: this.$deCode(this.data).type,
      showTagBtn: true,
      showBookBtn: true,
      saveLoading: false,
      imgLimit: 0, //可上传图片数量
      currentSelectImg: '', // 当前选中图片
      openContentDialog: false, // 点击查看弹窗
      openAddressSelector: false, // 地址选择器弹窗
      currentContent: '', //查看的内容
      social_role: '',
      datePickerType: ['year', 'month', 'date'],
      formatType: ['yyyy', 'yyyy-MM', 'yyyy-MM-dd', 'yyyy-MM-dd HH:mm'],
      openRelateCompanyDialog: false,
      RelateCompanyData: null,
      createdForm: {
        social_role: [
          'orderly',
          'stateEnterprise',
          'work',
          'soho',
          'teacher',
          'student',
          'other',
        ],
        member_type: ['single_member', 'company_member'],
        cert_type: [
          'chinese',
          'CN_HongKong',
          'CN_Macao',
          'HongKong_Macao_current',
          'other',
        ],
      },
      classData: [],
      openAddressSelector2: false,
      currentI: null,
    }
  },
  methods: {
    getDateType(obj) {
      if (obj.props.normal.dateType === 0) {
        return 'year'
      } else if (obj.props.normal.dateType === 1) {
        return 'month'
      } else if (obj.props.normal.dateType === 2) {
        return 'date'
      }
      return 'datetime'
    },
    updateClassData(res) {
      this.classData = res
    },
    // 获取会员详情
    getMemberDetail(id) {
      this.Loading = true
      MemberDetail(id)
        .then((res) => {
          const { data } = res
          if (id.id == 0) {
            // 新增会员时过滤会员类型表单
            data.detail = data.detail.filter((el) => el.field !== 'member_type')
          }

          // 对特殊字段排后
          // 把社会身份排到信息列表一最后
          data.base.push(
            data.base.splice(
              data.base.findIndex((el) => el.field === 'social_role'),
              1
            )[0]
          )
          if (data.detail.length) {
            data.detail.push(
              data.detail.splice(
                data.detail.findIndex((el) => el.field === 'cert_type'),
                1
              )[0]
            )
          }
          this.baseDataConfig = data.detail.length
            ? [
                { key: 'BaseDataOne', formKey: 'base', label: '信息列表一' },
                { key: 'BaseDataTwo', formKey: 'detail', label: '信息列表二' },
              ]
            : [{ key: 'BaseDataOne', formKey: 'base', label: '信息列表一' }]

          data.base.forEach((el) => {
            // 处理籍贯涉及省市区选择器相关数据
            if ([11, 14].includes(el.formType)) {
              el.user_data =
                el.user_data && typeof el.user_data === 'string'
                  ? el.user_data.split(',')
                  : ''
            } else if (el.field === 'avatar') {
              el.user_data = el.user_data || this.defaultAvatar
            } else if (el.field === 'phone') {
              if (this.$route.query.phone) el.user_data = el.user_data || this.$route.query.phone
            }
          })

          // 处理现居住地，家乡等涉及省市选择器相关数据
          data.detail.forEach((el) => {
            if ([11, 14].includes(el.formType)) {
              if (el.user_data && typeof el.user_data === 'string') {
                el.user_data = el.user_data.split(',')
              } else if (!el.user_data) {
                el.user_data = ''
              }
            }
            if (['base-time-picker'].includes(el.field)) {
              if (el.user_data) {
                el.user_data =
                  typeof el.user_data === 'string'
                    ? new Date(el.user_data).getTime()
                    : el.user_data * 1000
              } else {
                el.user_data = ''
              }
            }
          })
          this.detailForm = data
          // 接收数据对社会身份、证件类型做表单处理
          for (let key in data) {
            if (Array.isArray(this.detailForm[key])) {
              this.detailForm[key].forEach((item, i) => {
                if (
                  item.formType === 8 &&
                  ['cert_type', 'social_role', 'member_type'].includes(
                    item.field
                  )
                ) {
                  let val = item.user_data
                  let options = item.props.normal.options.filter(
                    (e) => e.is_open === 1
                  )
                  let fieldKey = item.field
                  let index = this.detailForm[key].findIndex(
                    (el) => el.field === fieldKey
                  )
                  if (options.findIndex((ele) => ele.field === val) !== -1) {
                    this.handleSelect(val, index, fieldKey, key)
                  } else {
                    item.user_data = ''
                  }
                }
              })
            }
          }
          this.Loading = false
        })
        .catch(() => {
          this.Loading = false
        })
    },
    getHouseholdRegistration() {
      getRegionSetting({ region_key: 'person_native_place_setting' }).then(
        (res) => {
          if (res.data.is_custom) {
            this.native_place_region_data = res.data.data
          } else {
            this.native_place_region_data = this.region_data
          }
        }
      )
    },
    // 序号计算
    indexMethod(index) {
      return index + 1
    },
    // 处理不同显示类型省市区选项
    getRegionData(selectType, field) {
      let region_data
      if (field === 'native_place') {
        region_data = JSON.parse(JSON.stringify(this.native_place_region_data || []))
      } else {
        region_data = JSON.parse(JSON.stringify(this.region_data || []))
      }
      if (selectType === 0) {
        region_data.map((el) => {
          el.children = undefined
        })
      } else if (selectType === 1) {
        region_data.map((el) => {
          el.children.map((item) => {
            item.children = undefined
          })
        })
      }
      return region_data
    },
    // 选择社会身份、证件类型
    handleSelect(val, i, key, formKey) {
      const formDataList =
        this.detailForm[formKey]
          .find((item) => item.field === key)
          .props.normal.options.find((el) => el.field === val).form_data || []
      this.detailForm[formKey] = this.detailForm[formKey].filter(
        (el) =>
          el.dependence === undefined ||
          !this.createdForm[key].includes(el.dependence)
      )
      this.detailForm[formKey].splice(
        formKey === 'base' ? i + 2 : i + 1,
        0,
        ...formDataList
      )
      this.contraryText = val === 'other' ? '反面' : '人像面'
      this.positiveText = ['chinese', 'HongKong_Macao_current'].includes(val)
        ? '国徽面'
        : ['CN_HongKong', 'CN_Macao'].includes(val)
        ? '区徽面'
        : '正面'
    },
    // 选择选项操作
    selectChange(val, selectData) {
      if (this.id) return
      const avatar = this.detailForm.base.find(
        (item) => item.field === 'avatar'
      ).user_data
      const isDefaultAvatar = avatar === this.defaultAvatar
      if (selectData.field === 'gender') {
        if (val === '男') {
          this.defaultAvatar =
            window.serverConfig.VUE_APP_ADMINURL +
            `/cyc/images/wxapps/icons/avatar${Math.round(
              Math.random() * 2 + 1
            )}.png`
        } else {
          this.defaultAvatar =
            window.serverConfig.VUE_APP_ADMINURL +
            `/cyc/images/wxapps/icons/avatar${Math.round(
              Math.random() * 2 + 4
            )}.png`
        }
        if (isDefaultAvatar) {
          this.detailForm.base.find(
            (item) => item.field === 'avatar'
          ).user_data = this.defaultAvatar
        }
      } else return
    },
    //选择地址
    handleAddressSelect(data, val, formKey, parentKey) {
      this.addressFormKey = {
        val,
        formKey,
        parentKey,
      }
      console.log(data, val, formKey, parentKey, 'handleAddressSelect')
      this.addressForm = {
        lat: data[val + '_address_lat'],
        lng: data[val + '_address_lng'],
        address: data[val + '_address'],
      }
      this.openAddressSelector = true
    },
    handleAddressSelect2(data, index) {
      ;(this.currentI = index),
        (this.addressForm = {
          lat: data['latitude'],
          lng: data['longitude'],
          address: data['address'],
        })
      this.openAddressSelector2 = true
    },
    // 关联企业
    handleToRelateCompany(val) {
      if (val.status === 0) {
        this.openRelateCompanyDialog = true
      } else {
        this.$router.push({
          name: 'MyCompanyList',
          params: this.$route.params.data,
        })
      }
    },
    handleChangeAddress(value) {
      const formOptions = this.detailForm[this.addressFormKey.parentKey].find(
        (item) => item.field === this.addressFormKey.formKey
      ).props.normal.options
      const addressForm = formOptions
        ? formOptions
            .find((el) => el.field === this.addressFormKey.val)
            .form_data.find(
              (e) => e.field === this.addressFormKey.val + '_address'
            ).user_data
        : this.detailForm[this.addressFormKey.parentKey].find(
            (item) => item.field === this.addressFormKey.formKey
          ).user_data
      if (this.addressFormKey.val) {
        addressForm[this.addressFormKey.val + '_address_lat'] = value.lat
        addressForm[this.addressFormKey.val + '_address_lng'] = value.lng
        addressForm[this.addressFormKey.val + '_address'] = value.address
      } else {
        addressForm[this.addressFormKey.formKey + '_lat'] = value.lat
        addressForm[this.addressFormKey.formKey + '_lng'] = value.lng
        addressForm[this.addressFormKey.formKey] = value.address
      }
    },
    handleChangeAddress2(value) {
      console.log(
        this.detailForm,
        'this.detailForm',
        this.detailForm.detail[this.currentI].user_data,
        '123'
      )
      this.detailForm.detail[this.currentI].user_data['latitude'] = value.lat
      this.detailForm.detail[this.currentI].user_data['longitude'] = value.lng
      this.detailForm.detail[this.currentI].user_data['address'] = value.address
    },
    // 新增职务
    addPostion(val) {},
    // 添加tag
    AddTag(key) {
      if (key === 'tags') {
        this.showTagBtn = false
        this.$nextTick((_) => {
          setTimeout(() => {
            this.$refs.saveTagInput[0].$refs.input.focus()
          }, 100)
        })
      } else {
        this.showBookBtn = false
        this.$nextTick((_) => {
          setTimeout(() => {
            this.$refs.saveBookInput.$refs.input.focus()
          }, 100)
        })
      }
    },
    handleInputConfirm(key) {
      let inputValue = this.inputValue
      if (key === 'tags') {
        if (inputValue) {
          this.detailForm.detail.tags.push(inputValue)
        }
        this.showTagBtn = true
      } else {
        if (inputValue) {
          this.detailForm.content.favorites.books.push(inputValue)
        }
        this.showBookBtn = true
      }
      this.inputValue = ''
    },
    // 删除tag
    CloseTag(i, key) {
      if (key === 'tags') {
        this.detailForm.detail.tags.splice(i, 1)
      } else {
        this.detailForm.content.favorites.books.splice(i, 1)
      }
    },
    //刷新列表
    refreshList() {
      const data = {
        id: this.$deCode(this.data).uid || this.id,
        // type: this.MemberType,
      }
      this.getMemberDetail(data)
    },
    // 点击保存
    HandleSave() {
      this.saveLoading = true
      const data = {
        id: this.$deCode(this.data).uid || this.id,
        ...this.detailForm,
      }
      if (!this.id) {
        data.positions = JSON.stringify(this.positionList)
      }
      data.content = JSON.stringify(data.content)
      const detailData = [...JSON.parse(JSON.stringify(data.detail))]
      const baseData = [...JSON.parse(JSON.stringify(data.base))]
      detailData.forEach((el) => {
        if ([11, 14].includes(el.formType)) {
          el.user_data = el.user_data ? el.user_data.join(',') : ''
        }
        if (['base-time-picker'].includes(el.field)) {
          if (el.user_data) {
            el.user_data /= 1000
          } else {
            el.user_data = null
          }
        }
      })
      baseData.forEach((el) => {
        if ([11, 14].includes(el.formType)) {
          el.user_data = el.user_data ? el.user_data.join(',') : ''
        }
      })
      console.log(
        detailData.map((el) => ({ field: el.field, value: el.user_data })),
        'OOOOOOOOOOOOOOO'
      )
      data.detail = JSON.stringify(detailData)
      data.base = JSON.stringify(baseData)
      // TODO: 这里添加数据
      console.log('当前版本： ' + this.tenantVersion) // school
      if (this.tenantVersion === 'school') {
        console.log('校友版提交') // school
        data.type = 1
        const classInfo = []
        for (let i = 0; i < this.classData.length; i++) {
          classInfo.push({
            class_id: this.classData[i].class_id,
            on_show: this.classData[i].on_show || 0,
          })
        }
        console.log('classInfo', classInfo)
        data.class_info = JSON.stringify(classInfo)
      }
      console.log('提交的data: ', data)
      userValidate(data)
        .then((res) => {
          if (this.$route.name === 'AddMember' && !this.isNext) {
            this.social_role = res.data.social_role
            this.isNext = true
            this.saveLoading = false
          } else {
            SaveUser(data)
              .then((res) => {
                this.$message.success(res.msg)
                if (this.$route.name === 'AddMember') {
                  this.$router.push({
                    name: 'CycUserList',
                    params: { tab: this.MemberType },
                    query: { role: 'user' },
                  })
                  this.$store.commit('setPositionList', [])
                } else {
                  this.isNext = false
                  this.refreshList()
                }
                this.saveLoading = false
              })
              .catch((err) => {
                this.saveLoading = false
              })
          }
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
    // 点击返回
    GoBack() {
      if (this.$route.name == 'AddMember' && this.isNext) {
        this.isNext = false
        return
      } else if (this.MemberType === 'view') {
        this.$router.go(-1)
        return
      } else if (this.$route.name === 'EditStudent') {
        this.$router.go(-1)
        return
      }
      // this.$router.push({
      //   name: 'CycUserList',
      //   params: { tab: this.MemberType },
      //   // query: { role: 'user' },
      // })
      this.$router.back()
    },
  },
  created() {
    this.refreshList()
    if (!this.id) {
      this.$store.commit('setPositionList', [])
    }

    getCityJson().then(({data, msg}) => {
      this.provinceCityData = data;
    }).catch(() => {
    })

    getAddressJson().then(({data, msg}) => {
      this.region_data = data;

      this.getHouseholdRegistration() // 籍贯数据要等待
    }).catch(() => {
    })

    this.$bus.on('getRelateCompanyResult', (res) => {
      this.RelateCompanyData = res
    })
  },
  // watch: {
  //   detailForm: {
  //     deep: true,
  //     handler(val) {
  //       if (val.positions.length === 0 && this.MemberType === "register") {
  //         this.GoBack();
  //       }
  //     },
  //   },
  // },
  computed: {
    positionList() {
      return this.$store.getters.positionList
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped>
.page-disable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.body {
  position: relative;
  padding: 0;
  min-height: 400px;
  // pointer-events: none;
  .organization-tag {
    margin: 15px 12px 10px;
  }

  .title {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 12px;
    border-bottom: 1px solid #e9e9e9;
  }

  .confirm-content {
    padding: 24px 4px;
  }

  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .base-content,
  .favorites-content {
    padding: 12px 4px 24px 4px;
  }

  .table-column {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
  }

  .input-box {
    width: 30%;
    padding-top: 12px;
    margin-right: 3%;
  }

  .thead-light {
    ::v-deep td {
      padding: 0 0 12px;
    }
  }

  .date-pick {
    display: flex;
  }

  .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .input-new-tag {
    width: 150px;
  }

  .tag-btn {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .id-card-img + .id-card-img {
    margin-left: 16px;
  }

  .img-btn {
    width: 150px;
    height: 96px;
    border: 1px dashed #dcdfe6;
  }

  .img-btn:hover {
    border: 1px dashed #3576ff;
  }

  .card-is,
  .card-the {
    width: 150px;
    height: 96px;
  }

  .images-box {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }

  .favorites-img {
    width: 150px;
    height: 96px;
    margin: 0 10px 10px 0;
  }

  ::v-deep.el-textarea__inner {
    min-height: 40px !important;
  }
}
.company-address {
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.two-img-upload {
  display: flex;
  width: 100%;
  ::v-deep.wall-content {
    width: 100%;
  }
  ::v-deep.photo-wall + .photo-wall {
    margin-left: 16px;
  }
}
.position-list {
  .title {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 12px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
  }
}
.content-title {
  padding: 8px 0 10px;
  position: relative;
}
.is-required {
  position: absolute;
  top: 0;
  left: -10px;
  color: #f56c6c;
}

.el-date-editor {
  ::v-deep.el-input__inner {
    padding-left: 15px;
  }
  ::v-deep.el-input__icon {
    display: none;
  }
}

.formType-btn {
  color: #3576ff;
  border: 1px solid #3576ff;
  @include nowrap();
  &:hover {
    background: #fff;
  }
}
</style>
